module MinGat.App.Public.Login {
    export function main(wasLoggedOut: boolean, enterUrl: string, bgImageUrl: string, loginFormLogoUrl: string)
    {
        $(() => {

            $("#loginFormWindow").fadeIn(700);

            var mainModel = window["loginModel"] = new MinGat.App.Public.LoginModel(enterUrl, bgImageUrl, loginFormLogoUrl);
            var pageBodyModel = new MinGat.App.Public.LoginPageBodyViewModel();

            $("#loginDataForm").on("submit", event => {
                event.preventDefault();
            });

            ko.applyBindingsIfNodeExists(mainModel, $(".login-area")[0]);
            ko.applyBindingsIfNodeExists(pageBodyModel, $(".login-body")[0]);

            if (wasLoggedOut) {
                mainModel.setError(Gatsoft.UI.Resources.get("mg.login.autoLoggedOut"));
            }
        });
    }
}